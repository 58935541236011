import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useState } from "react";
import { Field, Form, Formik, useFormik, useFormikContext } from "formik";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../../components/CTAButtonComponents";
import {
  useAddBillDetails,
  useLiveDate,
} from "../../../queries/companyStats/esopStats";
import { queryClient } from "../../../queries/client";
import { Select } from "../../../components/inputComponent";

type Props = {
  isShow: boolean;
  companyId: string;
  companyDetails: any;
  onClose: () => void;
};
export const WentLivePopup = ({
  isShow,
  companyId,
  companyDetails,
  onClose,
}: Props) => {
  const { mutate: addLiveDate } = useLiveDate();
  const formik = useFormik({
    initialValues: {
      LiveDate: "",
    },
    onSubmit: (values) => {
      const data = { ...values, companyId };
      addLiveDate(data, {
        onSuccess: () => {
          toast("Live Date Updated", {
            type: "success",
            autoClose: 2000,
          });
          queryClient.invalidateQueries("esopAdminDetails");
          onClose();
        },
        onError: () => {
          toast("Error in Adding Date", {
            type: "error",
            autoClose: 2000,
          });
        },
      });
    },
  });
  return (
    <Transition show={isShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 sm:w-full sm:max-w-md sm:p-6">
                <DialogTitle className="font-medium text-2xl">
                  Add Company Details
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                  <div className="flex py-5">
                    <label className="form-label m-0 pt-1 text-lg required">
                      Went Live Date
                    </label>
                    <input
                      type="date"
                      className="form-input max-w-44 rounded-md block border-inputBorderFocus ms-5"
                      name="LiveDate"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </div>
                  <div className="bg-gray-50 sm:flex sm:flex-row-reverse flex justify-between">
                    <PrimaryCTAButton
                      buttonSize={ButtonSize.MEDIUM}
                      type="submit"
                      disabled={false}
                    >
                      Submit
                    </PrimaryCTAButton>
                    <SecondaryCTAButton
                      buttonSize={ButtonSize.MEDIUM}
                      onClick={() => onClose()}
                    >
                      Cancel
                    </SecondaryCTAButton>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export const AddBillingDetails = ({
  companyId,
  companyDetails,
  isShow,
  onClose,
}: Props) => {
  const { mutate: addBillDetails } = useAddBillDetails();
  const [costDetails, setCostDetails] = useState([
    { priceType: "", from: 0, to: 0, price: 0, id: 1 },
  ]);
  const addDetails = () => {
    setCostDetails([
      ...costDetails,
      {
        priceType: "Slab",
        from: 0,
        to: 0,
        price: 0,
        id: costDetails.length + 1,
      },
    ]);
  };
  const billDetailsSchema = Yup.object().shape({
    engStartDate: Yup.string().required("Required"),
    readyToBillDate: Yup.string().required("Required"),
    pricingType: Yup.string().required("Required"),
    billFrequency: Yup.string().required("Required"),
    costDetails: Yup.array().of(
      Yup.object().shape({
        pricingType: Yup.string().required("Required"),
        from: Yup.number().required("Required"),
        to: Yup.number().required("Required"),
        price: Yup.number().required("Required"),
      }),
    ),
  });
  const handleDeleteDetails = (indexNumb: number) => {
    const newDetails = [...costDetails];
    if (indexNumb >= 0 && indexNumb < newDetails.length) {
      newDetails.splice(indexNumb, 1);
    }
    setCostDetails(newDetails);
  };
  return (
    <Transition show={isShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <DialogTitle className="font-medium text-2xl">
                  Add Billing Details
                </DialogTitle>
                <Formik
                  initialValues={{
                    engStartDate: "",
                    engEndDate: "",
                    onbDate: "",
                    readyToBillDate: "",
                    pricingType: "",
                    billFrequency: "",
                    remarks: "",
                    paymentDetails: "",
                    taxId: "",
                    periodOfContract: "0",
                    freeUserDetails: {
                      freeUsers: "0",
                      months: "0",
                    },
                    costDetails: [
                      {
                        pricingType: "Slab",
                        from: "0",
                        to: "0",
                        price: "0",
                      },
                    ],
                  }}
                  onSubmit={(values: any) => {
                    values.companyId = companyId;
                    addBillDetails(values, {
                      onSuccess: () => {
                        toast("Bill Details Added", {
                          type: "success",
                          autoClose: 2000,
                        });
                        queryClient.invalidateQueries("esopAdminDetails");
                        onClose();
                      },
                      onError: () => {
                        toast("Error in Adding Bill Details", {
                          type: "error",
                          autoClose: 2000,
                        });
                      },
                    });
                  }}
                  validationSchema={billDetailsSchema}
                >
                  {({ errors }) => {
                    return (
                      <Form>
                        <div className="grid grid-cols-2 py-5 gap-4">
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg required">
                              Engagement Start Date
                            </label>
                            <Field
                              type={"date"}
                              name={"engStartDate"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg required">
                              Engagement End Date
                            </label>
                            <Field
                              type={"date"}
                              name={"engEndDate"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg required">
                              Onboarding Date
                            </label>
                            <Field
                              type={"date"}
                              name={"onbDate"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg required">
                              Ready to Bill Date
                            </label>
                            <Field
                              type={"date"}
                              name={"readyToBillDate"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg required">
                              Pricing Type
                            </label>
                            <CustomSelect
                              name={"pricingType"}
                              options={["Individual", "Slab"]}
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg required">
                              Billing Frequency
                            </label>
                            <CustomSelect
                              name={"billFrequency"}
                              options={[
                                "Monthly",
                                "Quarterly",
                                "Half yearly",
                                "Annually",
                              ]}
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg">
                              Payment Details
                            </label>
                            <Field
                              type={"text"}
                              name={"paymentDetails"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg">
                              Tax ID
                            </label>
                            <Field
                              type={"text"}
                              name={"taxId"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg">
                              Period of Contract
                            </label>
                            <Field
                              type={"text"}
                              name={"periodOfContract"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                          <div className="flex justify-between">
                            <label className="form-label m-0 pt-1 text-lg">
                              Remarks
                            </label>
                            <Field
                              type={"text"}
                              name={"remarks"}
                              className={
                                "form-input max-w-44 rounded-md block border-inputBorderFocus"
                              }
                            />
                          </div>
                        </div>
                        <div className="pb-5">
                          <div className="font-medium text-xl">
                            Add Free User Details
                          </div>
                          <div className="">
                            <DisplayFreeUserDetails />
                          </div>
                        </div>
                        <div className="pb-5">
                          <div className="font-medium text-xl">
                            Cost Details
                            <PrimaryCTAButton
                              className="ms-2 rounded-3xl"
                              onClick={addDetails}
                            >
                              <Icon icon="material-symbols:add" />
                            </PrimaryCTAButton>
                          </div>
                          <div className="overflow-scroll h-52">
                            {costDetails.map((detail, index) => {
                              return (
                                <DisplayCostDetails
                                  key={detail.id}
                                  details={detail}
                                  index={index}
                                  totalEmp={
                                    companyDetails?.totalNumberOfEmployees
                                  }
                                  handleDelete={(number: number) => {
                                    handleDeleteDetails(number);
                                  }}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="bg-gray-50 sm:flex sm:flex-row-reverse flex justify-between">
                          <PrimaryCTAButton
                            buttonSize={ButtonSize.MEDIUM}
                            type="submit"
                          >
                            Submit
                          </PrimaryCTAButton>
                          <SecondaryCTAButton
                            buttonSize={ButtonSize.MEDIUM}
                            onClick={() => onClose()}
                          >
                            Cancel
                          </SecondaryCTAButton>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
function DisplayFreeUserDetails() {
  const formik = useFormikContext<any>(); //TODO: need to define type
  return (
    <div className="grid grid-cols-4 gap-3">
      <div className="">
        <label className="form-label m-0 pt-1 text-lg">Free Users</label>
        <Field
          type={"text"}
          name={"freeUsers"}
          className={
            "form-input max-w-44 rounded-md block border-inputBorderFocus"
          }
          onChange={(e: any) => {
            formik.setFieldValue(`freeUserDetails.freeUsers`, e.target.value);
          }}
        />
      </div>
      <div className="">
        <label className="form-label m-0 pt-1 text-lg">Months</label>
        <Field
          type={"text"}
          name={"months"}
          className={
            "form-input max-w-44 rounded-md block border-inputBorderFocus"
          }
          onChange={(e: any) => {
            formik.setFieldValue(`freeUserDetails.months`, e.target.value);
          }}
        />
      </div>
    </div>
  );
}
function DisplayCostDetails({
  key,
  handleDelete,
  index,
  totalEmp,
  details,
}: any) {
  const formik = useFormikContext<any>(); //TODO: need to define type
  return (
    <div className="grid grid-cols-5 gap-3">
      <div className="">
        <label className="form-label m-0 pt-1 text-lg required">
          Pricing Type
        </label>
        <CustomSelect
          name={"pricingTypes"}
          options={["Individual", "Slab"]}
          onChange={(e: any) => {
            formik.setFieldValue(
              `costDetails.${index}.pricingType`,
              e.target.value,
            );
          }}
        />
      </div>
      {details?.pricingType === "Slab" ||
      formik.values?.costDetails[index]?.pricingType === "Slab" ? (
        <>
          <div className="">
            <label className="form-label m-0 pt-1 text-lg">from</label>
            <Field
              type={"number"}
              name={"from"}
              className={
                "form-input max-w-44 rounded-md block border-inputBorderFocus"
              }
              onChange={(e: any) => {
                formik.setFieldValue(
                  `costDetails.${index}.from`,
                  e.target.value,
                );
              }}
            />
          </div>
          <div className="">
            <label className="form-label m-0 pt-1 text-lg">to</label>
            <Field
              type={"number"}
              name={"to"}
              className={
                "form-input max-w-44 rounded-md block border-inputBorderFocus"
              }
              onChange={(e: any) => {
                formik.setFieldValue(`costDetails.${index}.to`, e.target.value);
              }}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="">
        <label className="form-label m-0 pt-1 text-lg">price</label>
        <Field
          type={"number"}
          name={"price"}
          className={
            "form-input max-w-44 rounded-md block border-inputBorderFocus"
          }
          onChange={(e: any) => {
            formik.setFieldValue(`costDetails.${index}.price`, e.target.value);
          }}
        />
      </div>
      <div className=" mt-5">
        <PrimaryCTAButton
          disabled={!(index > 0)}
          onClick={() => {
            handleDelete(index);
          }}
        >
          <Icon icon="material-symbols:delete-outline" height="15" width="15" />
        </PrimaryCTAButton>
      </div>
    </div>
  );
}
const CustomSelect = ({ name, options, ...rest }: any) => {
  const formik = useFormikContext<any>(); //TODO: need to define type
  return (
    <Select
      className="w-auto max-w-44 form-input"
      name={name}
      value={formik?.values[name] || formik?.initialValues[name]}
      onChange={(e) => {
        formik.setFieldValue(name, e.target.value);
      }}
      options={options}
      multiple={false}
      {...rest}
    />
  );
};
