import { useLocation } from "react-router";
import { formatDate } from "date-fns";
import { useState } from "react";
import { VStack } from "../../components/utils";
import { PrimaryCTAButton } from "../../components/CTAButtonComponents";
import {
  CostDetails,
  EsopCompanyDetails,
  FreeUserDetails,
} from "../../types/companyStats";
import BillingReport from "./BillingReport";

function BillingDashboard() {
  const location = useLocation();
  const companyDetailsData: EsopCompanyDetails = location.state;
  const [isGenerateBillPopup, setIsGenerateBillPopup] = useState(false);
  const billingDetails =
    companyDetailsData.billingProfile[
      companyDetailsData.billingProfile.length - 1
    ];
  const freeUserDetails: FreeUserDetails = JSON.parse(
    billingDetails.freeUserDetails,
  );
  const costDetails: CostDetails[] = JSON.parse(billingDetails.costDetails);
  return (
    <VStack className="gap-4">
      <div className="p-5 rounded-lg shadow-custom ">
        <div>
          <div className="font-semibold text-2xl">Company Billing Details</div>
          <div className="grid grid-cols-2">
            <div className="text-lg flex">
              Company Name :
              <div className="font-semibold ms-2">
                {companyDetailsData?.companyName}
              </div>
            </div>
            <div className="text-lg flex">
              Tax Id :
              <div className="font-semibold ms-2">
                {billingDetails?.taxId ? billingDetails?.taxId : "-"}
              </div>
            </div>
            <div className="text-lg flex">
              Engagement Start :
              <div className="font-semibold ms-2">
                {formatDate(billingDetails?.engagementStartDate, "dd/MM/yyyy")}
              </div>
            </div>
            <div className="text-lg flex">
              Engagement End :
              <div className="font-semibold ms-2">
                {billingDetails?.engagementEndDate
                  ? formatDate(billingDetails?.engagementEndDate, "dd/MM/yyyy")
                  : "-"}
              </div>
            </div>
            <div className="text-lg flex">
              Billing Frequency :
              <div className="font-semibold ms-2">{billingDetails?.mode}</div>
            </div>
            <div className="text-lg flex">
              Onboarding Date :
              <div className="font-semibold ms-2">
                {billingDetails?.onboardingDate
                  ? formatDate(billingDetails?.onboardingDate, "dd/MM/yyyy")
                  : "-"}
              </div>
            </div>
            <div className="text-lg flex">
              Period Of Contract :
              <div className="font-semibold ms-2">
                {billingDetails?.periodOfContract}
              </div>
            </div>
            <div className="text-lg flex">
              Ready To Bill Date :
              <div className="font-semibold ms-2">
                {formatDate(billingDetails?.readyToBillDate, "dd/MM/yyyy")}
              </div>
            </div>
          </div>
          <div className="flex items-end gap-3 justify-end">
            <PrimaryCTAButton
              onClick={() => {
                setIsGenerateBillPopup(!isGenerateBillPopup);
              }}
            >
              Generate Invoice
            </PrimaryCTAButton>
            {isGenerateBillPopup && (
              <BillingReport
                isShow={isGenerateBillPopup}
                companyId={billingDetails.companyId}
                onClose={() => {
                  setIsGenerateBillPopup(!isGenerateBillPopup);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="p-5 rounded-lg shadow-custom">
          <div className="text-2xl font-semibold">Free User Details</div>
          <div className="text-[16px] space-y-2">
            <div className="flex">
              Total Users:
              <div>{freeUserDetails.freeUsers}</div>
            </div>
            <div className="flex">
              Total Period:
              <div>{freeUserDetails.months}</div>
            </div>
          </div>
        </div>
        <div className="p-5 rounded-lg shadow-custom col-span-2">
          <div className="text-2xl font-semibold">Cost Details</div>
          {costDetails.map((costDetail, index) => {
            return (
              <div className="flex text-[16px] space-y-2" key={index}>
                <div className="flex-1">{costDetail.pricingType}</div>
                <div className="flex-1">
                  {costDetail.from}-{costDetail.to}
                </div>
                <div className="flex-1">Price: {costDetail.price}</div>
              </div>
            );
          })}
        </div>
      </div>
    </VStack>
  );
}

export default BillingDashboard;
