import { useMutation, useQuery } from "react-query";
import {
  esopAddBillDetails,
  esopAdminDetails,
  esopCompanyDetails,
  esopDeleteUser,
  esopGenBillReport,
  esopGenFullBillReport,
  esopLiveDate,
  getEsopCompanyStats,
} from "../../api/esopStats";

export function useGetEsopCompanyStats() {
  return useQuery({
    queryKey: "getesopcompanystats",
    queryFn: getEsopCompanyStats,
  });
}
export function useAdminUserDetails() {
  return useMutation({
    mutationKey: "esopAdminDetails",
    mutationFn: esopAdminDetails,
  });
}
export function useCompanyDetails() {
  return useMutation({
    mutationKey: "esopCompanyDetails",
    mutationFn: esopCompanyDetails,
  });
}
export function useDeleteUser() {
  return useMutation({
    mutationKey: "esopDeleteUser",
    mutationFn: esopDeleteUser,
  });
}
export function useAddBillDetails() {
  return useMutation({
    mutationKey: "esopAddBillDetails",
    mutationFn: esopAddBillDetails,
  });
}
export function useLiveDate() {
  return useMutation({
    mutationKey: "esopLiveDate",
    mutationFn: esopLiveDate,
  });
}
export function useGenBillReport() {
  return useMutation({
    mutationKey: "esopGenBillREport",
    mutationFn: esopGenBillReport,
  });
}
export function useGenFullBillReport() {
  return useMutation({
    mutationKey: "esopGenFullBillReport",
    mutationFn: esopGenFullBillReport,
  });
}
