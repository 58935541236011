import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "./pages/MainLayout";
import CompaniesStatsWrapper from "./pages/companyStats/CompaniesStatsWrapper";
import CompanyDetailsPage from "./pages/companyStats/esopStats/CompanyDetailsPage";
import DashBoard from "./pages/dashboard/DashBoard";
import GlobalInvestorTable from "./pages/dashboard/GlobalInvestor";
import LoginPage from "./pages/login/loginPage";
import UploadWrapper from "./pages/onboarding/UploadWrapper";
import CreateOrder from "./pages/superAdmin/createOrder";
import MCAOrders from "./pages/superAdmin/mcaOrders";
import UserTable from "./pages/users/UserTable";
import { useAuthStore } from "./store";
import EsopStats from "./pages/companyStats/esopStats/EsopStats";
import CaptableStats from "./pages/companyStats/captableStats/CaptableStats";
import BillingDashboard from "./pages/billingPages/BillingDashboard";

export default function Router() {
  const { isAuthenticated, role } = useAuthStore();
  return useRoutes([
    {
      path: "/login",
      element: <LoginPage />,
    },
    { path: "/", element: <Navigate to="/captable" replace /> },
    {
      path: "/",
      element: isAuthenticated ? (
        <MainLayout />
      ) : (
        <Navigate to="/login" replace />
      ),
      children:
        role === "FinanceAdmin"
          ? [
              {
                path: "esopStats",
                element: <EsopStats />,
              },
              {
                path: "companyDetails",
                element: <CompanyDetailsPage />,
              },
              {
                path: "billingDetails",
                element: <BillingDashboard />,
              },
            ]
          : [
              {
                path: "captable",
                element: <DashBoard />,
              },
              {
                path: "users",
                element: <UserTable />,
              },
              {
                path: "upload",
                element: <UploadWrapper />,
              },
              {
                path: "esopStats",
                element: <EsopStats />,
              },
              {
                path: "captableStats",
                element: <CaptableStats />,
              },
              {
                path: "globalInvestor",
                element: <GlobalInvestorTable />,
              },
              {
                path: "createOrder",
                element: <CreateOrder />,
              },
              {
                path: "mcaCreatedOrder",
                element: <MCAOrders />,
              },
              {
                path: "companyDetails",
                element: <CompanyDetailsPage />,
              },
              {
                path: "billingDetails",
                element: <BillingDashboard />,
              },
            ],
    },
  ]);
}
function isAllowed(route: string) {
  return true;
}
