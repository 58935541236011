import jwtDecode from "jwt-decode";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Spinner } from "../../components/loader";
import { useLogin } from "../../queries/auth";
import { useAuthStore } from "../../store";

export default function LoginPage() {
  const { mutate: loginMutation } = useLogin();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuth } = useAuthStore();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center flex-1 h-screen px-6 py-12 bg-preference2 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="flex w-auto h-10 p-1 px-2 mx-auto text-sm font-semibold leading-6 bg-white rounded-md cursor-pointer group gap-x-3 whitespace-nowrap"
          src="https://i.postimg.cc/8Cv31svZ/Screenshot-2023-12-02-133325-removebg-preview.png"
          alt="hissa_logo"
        />
        <h1 className="mt-10 text-4xl font-bold leading-9 tracking-tight text-center text-white">
          Hissa Onboarding
        </h1>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          {/* <h2 className=" text-start font-bold leading-9 tracking-tight text-white">
            Sign in
          </h2> */}
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-white"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-white"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              onClick={() => {
                setLoading(true);
                loginMutation(
                  { email, password },
                  {
                    onSuccess: (data) => {
                      if (data.data.token) {
                        const decodedToken = jwtDecode(data.data.token) as {
                          email: string;
                          id: string;
                          name: string;
                          role: string;
                        };
                        setAuth({
                          role: decodedToken.role,
                          userName: decodedToken.name,
                          isAuthenticated: true,
                          accessToken: data.data.token,
                        });
                        if (decodedToken.role === "FinanceAdmin") {
                          navigate("/esopStats");
                        } else {
                          navigate("/captable");
                        }
                      }
                      setLoading(false);
                    },
                    onError: () => {
                      setLoading(false);
                    },
                  },
                );
              }}
              className="flex w-full justify-center rounded-md bg-preference1 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-preference1/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            >
              {loading ? <Spinner /> : "Sign in"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
