import { QueryFunctionContext } from "react-query";
import onBoardingApi from "./onBoardingApi";

export async function getEsopCompanyStats(
  context?: QueryFunctionContext,
): Promise<any> {
  return onBoardingApi
    .get(`adminStats/esopStats`)
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function esopAdminDetails(companyId: string): Promise<void> {
  return onBoardingApi
    .get(`adminStats/esopAdminDetails?companyId=${companyId}`)
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function esopCompanyDetails(companyId: string): Promise<void> {
  return onBoardingApi
    .get(`adminStats/esopCompanyDetails?companyId=${companyId}`)
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function esopDeleteUser(data: any): Promise<void> {
  return onBoardingApi
    .delete(`adminStats/esopDeleteUser`, { data })
    .then((res) => res.data.data)
    .catch((error: any) => error.data);
}
export async function esopAddBillDetails(data: any): Promise<void> {
  return onBoardingApi
    .post(`adminStats/addBillDetails`, { data })
    .then((res) => res.data)
    .catch((error: any) => error.data);
}
export async function esopLiveDate(data: any): Promise<void> {
  return onBoardingApi
    .patch(`adminStats/updateCompanyDetails`, { data })
    .then((res) => res.data)
    .catch((error: any) => error.data);
}
export async function esopGenBillReport(data: any): Promise<void> {
  return onBoardingApi
    .get(
      `adminStats/getBillReport?companyId=${data.companyId}&startDate=${data.startDate}&endDate=${data.endDate}&forBilling=${data.forBilling}`,
    )
    .then((res) => res.data)
    .catch((error: any) => error.data);
}
export async function esopGenFullBillReport(data: any): Promise<void> {
  return onBoardingApi
    .get(
      `adminStats/getFullBillReport?startDate=${data.startDate}&endDate=${data.endDate}&forBilling=${data.forBilling}`,
    )
    .then((res) => res.data)
    .catch((error: any) => error.data);
}
