import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import {
  ButtonSize,
  PrimaryCTAButton,
  SecondaryCTAButton,
} from "../../components/CTAButtonComponents";
import { downloadExcel } from "../../utils/download";
import {
  useGenBillReport,
  useGenFullBillReport,
} from "../../queries/companyStats/esopStats";

type Props = {
  isShow: boolean;
  companyId: string;
  onClose: () => void;
};
function BillingReport({ isShow, onClose, companyId }: Props) {
  const { mutate: getBillReport } = useGenBillReport(); //TODO: update the mutation call
  const { mutate: getFullBillReport } = useGenFullBillReport(); //TODO: update the mutation call
  const schema = Yup.object().shape({
    startDate: Yup.date().required(),
  });
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      forBilling: false,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (companyId === "") {
        const data = {
          startDate: values.startDate,
          endDate: values.endDate,
          forBilling: values.forBilling,
        };
        getFullBillReport(data, {
          //TODO: update the mutation call
          onSuccess: (data: any) => {
            onClose();
            downloadExcel(data.data, "Billing Invoice");
          },
          onError: (err: any) => {
            toast(err.message, { type: "error", autoClose: 2000 });
          },
        });
      } else {
        const data = {
          companyId,
          startDate: values.startDate,
          endDate: values.endDate,
          forBilling: values.forBilling,
        };
        getBillReport(data, {
          //TODO: update the mutation call
          onSuccess: (data: any) => {
            onClose();
            downloadExcel(data.data, "demoFile");
          },
          onError: (err: any) => {
            toast(err.message, { type: "error", autoClose: 2000 });
          },
        });
      }
    },
  });
  return (
    <Transition show={isShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose();
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 sm:w-full sm:max-w-md sm:p-6">
                <DialogTitle className="font-medium text-2xl">
                  Generate Bill Report
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-1 py-5 gap-4">
                    <div className="flex justify-between">
                      <label className="form-label m-0 pt-1 text-lg required">
                        Start Date
                      </label>
                      <input
                        type="date"
                        className="form-input max-w-44 rounded-md block border-inputBorderFocus"
                        name="startDate"
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.startDate}
                      />
                    </div>
                    <div className="flex justify-between">
                      <label className="form-label m-0 pt-1 text-lg required">
                        End Date
                      </label>
                      <input
                        type="date"
                        className="form-input max-w-44 rounded-md block border-inputBorderFocus"
                        name="endDate"
                        max={new Date().toISOString().split("T")[0]}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.endDate}
                      />
                    </div>
                    <div className="flex">
                      <label className="form-label m-0 pt-1 text-lg">
                        Update record as Billed
                      </label>
                      <input
                        type="checkbox"
                        className="peer mt-2 ms-2 h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300"
                        name="forBilling"
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:flex sm:flex-row-reverse flex justify-between">
                    <PrimaryCTAButton
                      buttonSize={ButtonSize.MEDIUM}
                      type="submit"
                      disabled={false}
                    >
                      Download
                    </PrimaryCTAButton>
                    <SecondaryCTAButton
                      buttonSize={ButtonSize.MEDIUM}
                      onClick={() => onClose()}
                    >
                      Cancel
                    </SecondaryCTAButton>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default BillingReport;
